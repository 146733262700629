import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getBuildIdQuery, ILink } from '@ncg/data';

@Component({
    selector: 'ncg-mobile-header-link',
    template: `<a *ngIf="link.isExternal; else internalLinkTmpl" [href]="link.url" [target]="link.target" class="mobile-header-link">
            <ng-container *ngTemplateOutlet="modelOverviewSymbolTmpl; context: { title: link.name }"></ng-container>
        </a>
        <ng-template #internalLinkTmpl>
            <a [routerLink]="[link.url]" class="mobile-header-link">
                <ng-container *ngTemplateOutlet="modelOverviewSymbolTmpl; context: { title: link.name }"></ng-container>
            </a>
        </ng-template>
        <ng-template #modelOverviewSymbolTmpl let-title="title">
            <svg-icon-sprite
                [src]="'assets/images/sprite.svg' + buildIdQuery + '#' + icon"
                [viewBox]="'0 0 30 30'"
                [width]="'30px'"
                [height]="'30px'"
            ></svg-icon-sprite>
            <span *ngIf="title">{{ title }}</span>
        </ng-template>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderLinkComponent {
    @Input() link: ILink;
    @Input() icon: string = 'car';

    buildIdQuery = getBuildIdQuery();
}
